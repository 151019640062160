<template>
	<div>
		<f-default-header
			:title="$t('pages.settings.title')"
			:subtitle="$t('pages.settings.subtitle.jobroles')" />

		<f-list-table />

		<f-actions-btn-holder>
			<v-btn elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
			&nbsp;
			<v-btn color="primary" elevation="0" large @click="create">
				{{ $t("labels.btnNew") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FListTable from "../../../components/settings/jobRoles/List.vue";

export default {
	components: {
		FListTable,
	},
	created() {
		this.getData();
	},
	methods: {
		async getData() {
			await this.$store.dispatch("jobRoles/list");
		},
		create() {
			this.$router.push({
				name: "settings-job-roles-create",
			});
		},
		back() {
			this.$router.push({
				name: "settings",
			});
		},
	},
};
</script>
